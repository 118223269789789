<script>
import Gen from '@helper/Gen.js';
import env from '../../env.js';

export default {
	props: ['name', 'value', 'config', 'type'],
	computed:{
		page(){ return this.$root.page },
		myInsert(){
			if(this.type == 'simple') return []
			if(this.type == 'noImage') return ['embedsemantic', 'Table', 'HorizontalRule', 'SpecialChar', 'Iframe']
			return ['Image','embedsemantic', 'Table', 'HorizontalRule', 'SpecialChar', 'Iframe']
		},
		myLinks(){
			if(this.type == 'simple') return []
			return ['Link', 'Unlink', 'Anchor']
		},
		myParagraph(){
			if(this.type == 'simple') return []
			return ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', 'CreateDiv', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-', 'BidiLtr', 'BidiRtl', 'Language']
		},
		myBasicstyles(){
			if(this.type == 'simple') return ['Bold','Italic','Underline']
			return ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'CopyFormatting', 'RemoveFormat']
		},
		myForms(){
			if(this.type == 'simple') return []
			return ['Form', 'Checkbox', 'Radio', 'TextField', 'Textarea', 'Select', 'Button', 'HiddenField']
		},
		myDocument(){
			if(this.type == 'simple') return []
			return ['Save', 'NewPage', 'Preview', 'Print', '-', 'Templates']
		},
		myStyle(){
			if(this.type == 'simple') return ['FontSize','Font','Format','Styles']
			return ['Styles', 'Format', 'Font', 'FontSize']
		},
		myClipboard(){
			if(this.type == 'simple') return ['Cut','Copy','Paste','PasteText']
			return ['Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord','pastecode', '-', 'Undo', 'Redo']
		},
		myColor(){
			if(this.type == 'simple') return []
			return ['TextColor', 'BGColor']
		},
		myTools(){
			if(this.type == 'simple') return ['Source']
			return ['Maximize', 'ShowBlocks', 'Source']
		},

	},
	async mounted(){
		global.CKEDITOR_BASEPATH = env.baseUrl+'/plugins/ckeditor/'
		await Gen.loadScript("/plugins/ckeditor/ckeditor.js")
		
		this.init()
	},
	methods:{
		init(){
			this.ck = global.CKEDITOR.replace(this.name, Object.assign({
				height: '200px',
				toolbar: [
					{ name: 'styles', items: this.myStyle },
					{ name: 'document', items: this.myDocument },
					{ name: 'forms', items: this.myForms },
					{ name: 'basicstyles', items: this.myBasicstyles },
					{ name: 'paragraph', items: this.myParagraph },
					'/',
					{ name: 'clipboard', items: this.myClipboard },
					{ name: 'links', items: this.myLinks },
					{ name: 'insert', items: this.myInsert },
					{ name: 'colors', items: this.myColor },
					{ name: 'tools', items: this.myTools },
					// { name: 'others', items: ['-'] }
				],
				// autoGrow_maxHeight: 500,
				extraPlugins: ['autogrow','pastecode'/* ,'imagebrowser' */,'wordcount','youtube','colorbutton','panelbutton'],
				allowedContent: true,
				embed_provider: '//ckeditor.iframe.ly/api/oembed?url={url}&callback={callback}',
				// embed_provider: '//noembed.com/embed?url={url}&callback={callback}',
				filebrowserUploadUrl: env.baseUrl+'/ajax/upload?pageType=editor',
			}, this.config || {}))
			this.ck.on('change', () => {
				this.$emit('input', this.ck.getData())
				this.onEdit = true
				this.$el.value = this.ck.getData()
				Gen.delay(() => {
					this.onEdit = false
					$(this.$el).valid()
				}, 300)
			})
			this.ck.on("instanceReady",()=>{ setTimeout(()=>{
				if(this.value){
					this.$el.value = this.value
					this.ck.setData(this.value)
				}
			}, 300) })
		}
	},
	watch: {
		value: function (v) {
			if(!v) this.ck.setData("")
			if (!this.onEdit){
				if(this.ck) this.ck.setData(v)
			}
		}
	},
	destroyed: function () {
    $("#cke_"+this.name).remove();
  }
}
</script>

<template>
  <textarea :name="name" :id="name"></textarea>
</template>